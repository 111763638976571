import { render, staticRenderFns } from "./I18n.vue?vue&type=template&id=189b0654&scoped=true"
var script = {}
import style0 from "./I18n.vue?vue&type=style&index=0&id=189b0654&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189b0654",
  null
  
)

export default component.exports